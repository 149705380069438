// Model https://github.com/marmelab/react-admin/blob/master/packages/ra-language-french/src/index.ts

module.exports = {
  app: {
    action: {
      create: "Add",
      list: "List",
      search: "Search",
      newSearch: "Start a new search",
      contactPlace: "Contact the place",
      successAddBookmark: "Added to your bookmarks",
      successRemoveBookmark: "Removed from your bookmarks",
      successMessageSent: "Votre message a bien été envoyé",
      publish: "publish",
      unpublish: "unpublish",
      published: "The resource has been published",
      unpublished: "The resource has been unpublished",
      userCreation: "User creation in progress...",
      userCreated: "User created",
      close: "Close",
      send: "Send",
      listView: "list view",
      mapView: "map view",
      calendarView: "calendar view",
      filters: "filters",
      removeFilters: "remove all filters",
      showFilters: "Show all filters",
      help: "Help",
      contactPathOrganizer: "Contact the organizers of this path",
      organization: {
        contact: "Contact the organization",
        create: "Add an organization",
        myOrganizations: "My organizations",
      },
      person: {
        contact: "Contact",
      },
      event: {
        event: "Events",
        contact: "Contact the organizers",
        create: "Add an event",
        register: "I register",
      },
      place: {
        myPlaces: "My places",
        create: "Add a place",
      },
      course: {
        create: "Add a course",
      },
      offerAndNeed: {
        contact: "Contact the writer",
        mine: "My announcements",
        create: "Add an announcement",
      },
      alert: {
        mine: "My alerts",
        create: "Add an alert",
      }
    },
    page: {
      home: "Welcome to the Transition Pathways !",
    },
    card: {
      organization: {
        description: "Description",
        hasRegion: "Region",
        organizationHostedIn: "Where are we",
        affiliates: "Has contributor",
        supports: "Contributor of",
        organizes: "Upcoming activities",
        onlyFutureEvents: "Display only upcoming activities",
        partnerOf: "Partner of"
      },
      person: {
        hasRegion: "Region",
        hasType: "Person Type",
        proposes: "Host of",
        affiliatedBy: "Member of",
        supports: "Contributor of",
        organizes: "Organizer of",
        mentorOn: "Mentor on",
      },
      event: {
        duration: "Duration",
        onlyFutureEvents: "Display only upcoming events",
        localisation: "Localisation",
      },
      course: {
        onlyFutureCourse: "Only display upcoming trips",
      },
      registration: {
        bookingPeriod: "Booking date: from ",
        from: "from",
        to: " to ",
        bookedOn: " Booked on: "
      },
      place: "Place",
      location: "Location",
      similar: "similar",
      duration: {
        months: "months",
        days: "days",
        hours: "hours",
        minutes: "minutes",
        minute: "minute",
      }
    },
    block: {
      search: "Follow the pathways of transition",
    },
    input: {
      courseType: "Course type",
      sector: "Activity sector",
      topic: "Keyword",
      region: "Region",
      targetAudience: "Target Audience",
      listView: 'List View',
      dates: 'Dates',
      organizer: 'Organizer',
      facilitator: 'Facilitator',
      linkedPath: 'Linked Path',
      placeType: "Place type",
      offerAndNeedType: "Announcement type",
      skills: "Skills",
      capacityFrom: "From",
      capacityTo: "to",
      miminumPerson: "minimum people",
      maximumPerson: "maximum people",
      externalForm: "Link to external registration system",
      chooseDefaultForm: "Choose the default form",
      defaultForm: "Default form",
      chooseRegistrationType: "Choose a registration option",
      chooseJotform: "Choose a JotForm",
      organization: {
        label: "Organization Name",
        hasType: "What type of organization?",
        comment: "Could you describe it in one sentence?",
        description: "Feel free to describe it in more detail here",
        depictedBy: "Add a logo!",
        hasSector: "In which sectors does it operate?",
        hasTopic: "What keywords would you use to characterize it?",
        hasFinality: "What goals are you pursuing?",
        intentions: "What are your intentions in coming to the paths of transition?",
        practicalConditions: "Can you accommodate travelers? If yes, under what conditions?",
        maximumCapacity: "How many travelers maximum?",
        hasCourseType: "In what travel modes can you accommodate travelers?",
        produces: "What skills can you offer?",
        partnerOf: "Do you have partners on the Paths of Transition?",
        inspiredBy: "Organizations that inspire you?",
        affiliates: "Members of your organization on the Paths of Transition?",
        supports: "Are you joining a path?",
        organizationHostedIn: "In which place(s) are you present?",
        capacityUnit: "persons",
      },
      event: {
        type: "Event type",
        hasTargetAudience: "Target Audience",
        listView: 'List View',
        duplicate: "Duplicate an existing event?",
      },
      person: {
        firstName: "Your first name",
        lastName: "Your last name",
        email: "Your email",
        message: "Message",
        alternativeLabel: "Your username",
        comment: "Who are you in one sentence?",
        homePage: "A link about you?",
        description: "Feel free to describe yourself in more detail!",
        intentions: "Your intentions in coming to the paths of transition?",
        depictedBy: "Your photo! (It's important for the platform's friendliness)",
        hasSector: "Are you interested in specific activity sectors?",
        hasTopic: "What are your areas of interest?",
        offers: "What are your current skills?",
        aims: "What skills are you looking for?",
        hasFinality: "What goals are you pursuing?",
        phone: "Your phone number?",
        website: "Your website?",
        hasLocation: "Where do you live?",
        affiliatedBy: "Member of one (or more) organizations?",
        inspiredBy: "Are there individuals who inspire you?"
      }
    },
    helper: {
      choose: "Choose...",
      nonVisible: "Not visible on the platform",
      publicPhone: "Publicly displayed phone number on the page",
      publicLink: "Link publicly displayed on the page",
      directRegistration: "If not, travelers will need to contact you directly via a contact form beforehand",
      practicalConditions: "Specify if equipment is needed, registration procedures, accommodation, meals...",
      accessibility: "Specify the event's accessibility for people with disabilities",
      full: "Check this box if the event is full",
      financialSupport: "If eligible, specify the types of funding (CPF, Qualiopi...)",
      connectToAddBookmark: "Connect to add a page to your bookmarks",
      userCreation: "If you create a user of type Actor, they will automatically receive an invitation at their email address.",
      courseCreation: "You must first have created the events corresponding to the different stages of your trip."
    },
    message: {
      welcome: "An open, nomadic, peer-to-peer university focusing on transition issues and professions. Learn while you travel, and share knowledge, experience and know-how on the ecological, energy, cultural, social, economic, technological and societal issues of our time.",
      login: "Welcome to Transition Pathways! Creating an account on the platform will allow you to provide information about your profile: who you are, what you're involved in, what you're looking for, and what you have to offer. It will also enable you to save your favorites (actors, places, events, journeys) and join the Transition Pathways community! By logging in, you confirm your adherence to the Transition Pathways Charter.", //TODO @Tonfa add <Link to='/Page/charte-des-chemins-de-la-transition/show' target='_blank'> to the text as hyperlink 
      organization: {
        involvedActivities: "This organization is involved in multiple activities. Click to learn more and/or participate.",
      },
      errorAuth: "Please log in to access the contact form",
      errorRegistration: "You must be logged in to register",
      moreEvents: "This place offers multiple events. Click on it to learn more and/or participate.",
      results: "result(s)",
      noResult: "No result found",
      topBarMessage: "The place to share and learn while traveling",
      topBarButton: "Support the platform with a donation",
      betaVersion: "Beta version",
      reminderToFill: "Don't forget to fill in the",
      reminderSecondTab: "second tab",
      reminderEndText: "of the form, that's where we ask the most interesting questions!",
      noSavedBookmarks: "No saved favorites",
      error: "An issue occurred",
      welcomeContent: {
        title: "Travel, Discover, Learn,",
        subTitle: "Share, Spread...",
        text: "Traveling is primarily about taking a break from our lives, a parenthesis that allows us to see what's happening elsewhere, to venture out and discover new practices. Transition Pathways offers a journey from place to place, off the beaten path, to meet transition actors (energy, social, solidarity...). And why not, in your turn, share your knowledge.",
      },
      home: {
        places: "Places",
        toVisit: "To visit",
        discoverPlaces: "Discover places involved in transitions, activities that are practiced there, meet the actors and actresses who are making tomorrow's world!",
        seeAllPlaces: "See all places",
        agenda: "Agenda",
        events: "Events",
        discoverEvents: "Discover events around the transmission of knowledge, open up your field of possibilities, learn, train yourself in the challenges and professions of transition!",
        seeAllEvents: "See all events",
        paths: "Paths",
        toDiscover: "To discover",
        dicoverPaths: "Some inspirations that we offer you, discover a specific theme, create your own program à la carte!",
        seeAllPaths: "See all paths",
        courses: "Our travels",
        themesAndLocation: "Themes & locations",
        discoverCourses: "Discover preconceived journeys, meeting several transition initiatives, on a given theme and/or geographical sector!",
        seeAllCourses: "See all trips",
        followUs:"Follow us",
      },
      coursesTypes: {
        title: "A Unique Adventure",
        subTitle: "Tailored to Your Knowledge Level",
        learnByTravelling: "Learning While Traveling",
        mode: "in",
        discovery: "Discovery",
        discoverySubtext: "Whether solo, with family, or friends, explore the Transition Pathways by walking, horseback riding, or cycling between places.",
        discoverySubtextAbout: "I'm curious, I open my eyes to transition-related professions by visiting inspiring places during my journey.",
        learning: "Learner",
        learningSubtext: "Spend a few hours or a day meeting transition actors to enrich yourself with their practices.",
        learningSubtextAbout: "A practice interests me, I want to know more. Take advantage of these multi-day journeys to visit different places and meet project leaders.",
        immersion: "Immersive",
        immersionSubtext: "Contribute and learn by immersing yourself with transition actors, offering your participation in exchange for accommodation and meals.",
        immersionSubtextAbout: "I want to learn by actively participating. These multi-week journeys allow you to learn through hands-on experience and contribute to a project of your choice.",
        mentoring: "Mentoring",
        mentoringSubtext: "Enroll in certified and qualifying itinerant training programs, immersing yourself with expert transition actors.",
        mentoringSubtextAbout: "I'm ready to commit to training in a specific practice. You'll be deeply immersed in a location and complete theoretical modules for validation of your skills.",
        courseDiscovery: "Discovery Journey",
        courseLearning: "Learning Journey",
        courseImmersion: "Immersive Journey",
        courseMentoring: "Mentorship",
      },
      partners: {
        withTheSupport: "With the support of",
        financialPartners: "our financial partners",
        businessPartners: "our business partners",
      },
      traveler: {
        joinCommunityText: "Do you want to suggest a place, an event or travel ideas?",
        joinCommunityButton: "Join the community",
      },
      goals: {
        title: "Our Goal",
        subTitle: "Empowering Learning Through Travel",
        subText: "The Transition Pathways is first and foremost a desire to connect and link actors of transition, but also to transmit in order to see initiatives multiply. Let's act together to contribute to the discovery, education, and networking of individuals who want to engage in the Transition, so that the dream of witnessing solidarity and ecological worlds blossom, mature, and intertwine becomes rooted in our reality.",
        goal1: "Connecting Territories & Valuing Existing Initiatives",
        text1goal1: "Referencing inspirational places and initiatives open to transmission that contribute to transition (ecological, energy, social, cultural, economic...).",
        text2goal1: "Promoting and energizing the attractiveness of rural areas through a new flow dynamic focused on discovering local transition actors.",
        text3goal1: "Interconnecting transition actors and giving them visibility. Creating inter-project/inter-territory synergies.",
        text4goal1: "Accelerating projects through assistance provided by travelers.",
        goal2: "Traveling while learning, training in the challenges and professions of transition",
        text1goal2: "Immersive journeys by activity sectors to easily access emerging practices in all fields of transition.",
        text2goal2: "Journeys with multiple levels of discovery and learning through doing.",
        text3goal2: "The freedom to create one's own paths according to interests, availability, and the desired level of learning (discovering, learning by doing, training in professions).",
        text4goal2: "Enabling the discovery and learning of transition professions through contact with passionate and committed actors.",
        goal3: "The Traveler as a Pollinator of New Practices",
        text1goal3: "Contributing to the spread of projects with strong local impact and growing the transition ecosystem.",
        text2goal3: "Stimulating the emergence of new initiatives.",
        text3goal3: "Creating connections through a space for exchanging and sharing information, ideas, and projects.",
        text4goal3: "Amplifying socio-ecological transition by involving citizens in the implementation of transition actions.",
        text5goal3: "Increasing the resilience of the territory through the development of local solutions."
      },
      categories: {
        title: "Diverse categories",
        subTitle: "to explore",
      },
      footer: {
        codeOfConduct: "Transition Pathways Charter",
        legalNotice: "Legal Notice",
        dataPolicy: "Data Management Policy",
        join: "Join the community",
        joinSubText1: "Do you want to share your knowledge about transition and perhaps host travelers for a visit or more to train them?",
        joinSubText2: "Do you want to register your place or an event on our platform to give it visibility?",
        joinButton: "I'm in",
        usefulLinks: "Useful Links",
        about: "About",
        forum: "Our Forum",
        website: "Our Website",
      },
    },
    tab: {
      path: {
        about: "About this path",
        links: "Path links",
        place: "Places",
        event: "Events",
        course: "Journeys",
      },
      organization: {
        about: "About this organization",
        links: "Organization links",
        contact: "Contact",
        visibility: "Visibility",
        skills: "Skills",
        accommodation: "Accommodation",
        activities: "Activities",
        inspiredBy: "Inspired by",
      },
      person: {
        about: "About",
        affiliates: "Connected with you",
        links: "Linked with the organization",
        contact: "Contact",
        visibility: "Visibility",
        skills: "Skills",
        inspiredBy: "Inspired by",
      },
      course: {
        about: "About the journey",
        pathway: "Journey program",
        location: "Location",
        title: "Our journeys",
        subTitle: "For more inspiration",
        linkText: "View all journeys",
        learningObjectives: "Learning Objectives",
        practicalConditions: "Practical Information",
      },
      event: {
        about: "About the event",
        pathway: "Journey Program",
        location: "Location",
        title: "Our journeys",
        subTitle: "For more inspiration",
        linkText: "See all journeys",
        learningObjectives: "Learning Objectives",
        practicalConditions: "Practical Information",
      },
      place: {
        about: "About the place",
        link: "Related to the place",
        contact: "Contact",
        title: "More places",
        subTitle: "For more inspiration",
        linkText: "See all places"
      },
      offerAndNeed: {
        description: "Description",
        contact: "Contact",
        visibility: "Visibility"
      },
      economicalConditions: 'Economical Conditions',
      accommodation: 'Accommodation',
      contact: 'Contact',
      visibility: 'Visibility',
      skills: 'Skills',
      inspiredBy: 'Inspired by',
      links: 'Links',
      description: 'Description',
      dates: 'Dates',
      organizer: 'Organizer',
      facilitator: 'Facilitator',
      linkedPath: 'Linked Path',
    },
    notification: {
      full: "This event is full",
      partOf: "This event is part of the journey",
      financialSupport: "Discover various funding mechanisms",
    },
    validation: {},
    menu: {
      bookmarks: "My bookmarks",
      places: "My Places",
      events: "My Events",
      courses: "My Courses",
      organizations: "My Organizations",
      reservations: "My Bookings",
      login: "Login",
      signup: "Sign Up",
      profile: "My Profile",
      offerAndNeed: "Announcements"
    },
    nav: {
      path: "Paths",
      place: "Places",
      event: "Events",
      course: "Courses",
      offerAndNeed: "Announcements"
    },
    bookmark: {
      title: "My bookmarks",
      path: "paths",
      place: "places",
      event: "events",
      course: "courses",
      offerAndNeed: "announcements",
      pathHeadComment: "To compose my trip",
      placeHeadComment: "To compose my trip",
      eventHeadComment: "To compose my trip",
      courseHeadComment: "To compose my trip",
      offerAndNeedHeadComment: "To compose my trip",
    }
  },
};