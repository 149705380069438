import React from 'react';
import { SvgIcon } from '@material-ui/core';

const MapIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 18 18" {...props}>
      <path d="M17.4369 7.87451C17.1264 7.87451 16.8744 8.1265 16.8744 8.43698V14.8063L12.3747 16.6062V10.6868C12.3747 10.3764 12.1227 10.1244 11.8123 10.1244C11.5018 10.1244 11.2498 10.3764 11.2498 10.6868V16.6051L6.75007 14.8052V5.89351L9.35653 6.93632C9.64114 7.05107 9.97187 6.91157 10.0877 6.62247C10.2036 6.33449 10.063 6.00713 9.77388 5.89126L6.40696 4.5436C6.40696 4.5436 6.40696 4.5436 6.40584 4.5436L6.39684 4.54022C6.26297 4.48623 6.11336 4.48623 5.97949 4.54022L5.96937 4.5436C5.96937 4.5436 5.96937 4.5436 5.96824 4.5436L0.353716 6.79008C0.141105 6.87558 0.000488281 7.08144 0.000488281 7.31205V17.4364C0.000488281 17.6232 0.0938575 17.7975 0.247973 17.9021C0.342467 17.9663 0.451585 17.9989 0.562953 17.9989C0.633824 17.9989 0.704694 17.9854 0.77219 17.9584L6.1876 15.7929L11.5929 17.955C11.5929 17.955 11.5929 17.955 11.594 17.955L11.6041 17.9595C11.738 18.0135 11.8876 18.0135 12.0215 17.9595L12.0316 17.955C12.0316 17.955 12.0316 17.955 12.0327 17.955L17.6473 15.7096C17.8587 15.623 17.9994 15.4172 17.9994 15.1866V8.43698C17.9994 8.1265 17.7474 7.87451 17.4369 7.87451ZM5.62514 14.8052L1.12542 16.6051V7.69227L5.62514 5.89239V14.8052Z" />
      <path d="M14.0622 2.24985C13.1318 2.24985 12.3748 3.00693 12.3748 3.93724C12.3748 4.86756 13.1318 5.62464 14.0622 5.62464C14.9925 5.62464 15.7495 4.86756 15.7495 3.93724C15.7495 3.00693 14.9925 2.24985 14.0622 2.24985ZM14.0622 4.49971C13.7517 4.49971 13.4997 4.24772 13.4997 3.93724C13.4997 3.62676 13.7517 3.37478 14.0622 3.37478C14.3726 3.37478 14.6246 3.62676 14.6246 3.93724C14.6246 4.24772 14.3726 4.49971 14.0622 4.49971Z" />
      <path d="M14.0623 0C11.8911 0 10.125 1.76614 10.125 3.93725C10.125 5.95763 13.2838 9.53603 13.6438 9.93763C13.7506 10.0557 13.9025 10.1244 14.0623 10.1244C14.222 10.1244 14.3739 10.0557 14.4807 9.93763C14.8407 9.53603 17.9995 5.95763 17.9995 3.93725C17.9995 1.76614 16.2334 0 14.0623 0ZM14.0623 8.70358C12.8338 7.2468 11.2499 5.01044 11.2499 3.93725C11.2499 2.3871 12.5121 1.12493 14.0623 1.12493C15.6124 1.12493 16.8746 2.3871 16.8746 3.93725C16.8746 5.00931 15.2907 7.2468 14.0623 8.70358Z" />
    </SvgIcon>
  );
};

export default MapIcon;
