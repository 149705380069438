import React from 'react';
import { SvgIcon } from '@material-ui/core';

const CourseIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 28 28" {...props}>
      <path d="M8 10C5.79086 10 4 11.7909 4 14C4 15.1046 4.89543 16 6 16H14C15.1046 16 16 15.1046 16 14C16 11.7909 14.2091 10 12 10H8ZM6 14C6 12.8954 6.89543 12 8 12H12C13.1046 12 14 12.8954 14 14H6ZM10 0C7.79086 0 6 1.79086 6 4V4.83209C2.46819 6.3752 0 9.89936 0 14V23C0 25.7614 2.23858 28 5 28H15C17.7614 28 20 25.7614 20 23V14C20 9.89936 17.5318 6.3752 14 4.83209V4C14 1.79086 12.2091 0 10 0ZM18 18H2V14C2 9.58172 5.58172 6 10 6C14.4183 6 18 9.58172 18 14V18ZM6 23C6 23.5523 6.44772 24 7 24C7.55228 24 8 23.5523 8 23V20H18V23C18 24.6569 16.6569 26 15 26H5C3.34315 26 2 24.6569 2 23V20H6V23ZM10 4C9.31507 4 8.64622 4.06886 8 4.20004V4C8 2.89543 8.89543 2 10 2C11.1046 2 12 2.89543 12 4V4.20004C11.3538 4.06886 10.6849 4 10 4Z" />
    </SvgIcon>
  );
};

export default CourseIcon;
