import React from 'react';
import { SvgIcon } from '@material-ui/core';

const CourseIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 12 12" {...props}>
      <path d="M11.9203 0.0403123C11.8701 0.0025078 11.8051 -0.00949213 11.7449 0.00771089L9.64492 0.607707L9.99997 0.999939L11.5999 0.607707V10.8491L8.99814 11.5924L6.05155 10.8066C6.01754 10.7976 5.98215 10.7976 5.94836 10.8066L2.99997 11.4999L0.567153 10.8066V0.792511L2.99997 1.49994C2.96516 1.50913 3.03496 1.51013 2.99997 1.49994L5.94833 0.792511L7.99997 0.999939L7.85152 0.486747L6.05153 0.00674996C6.01752 -0.00224999 5.98213 -0.00224999 5.94833 0.00674996L3.00176 0.792511L0.254999 0.00771089C0.194788 -0.00949213 0.129796 0.0025078 0.0795933 0.0403123C0.0293905 0.0781167 0 0.137109 0 0.199921V10.9999C0 11.0891 0.0592028 11.1676 0.145007 11.1923L2.945 11.9922C2.97999 12.0022 3.01679 12.0024 3.05159 11.993L5.99996 11.2068L8.94834 11.993C8.96533 11.9974 8.98253 11.9998 8.99995 11.9998C9.01856 11.9998 9.03695 11.9972 9.05474 11.9922L11.8547 11.1923C11.9407 11.1676 11.9999 11.0891 11.9999 10.9999V0.199897C11.9999 0.137109 11.9705 0.0781167 11.9203 0.0403123Z" />
      <path d="M2.52815 6C2.27636 6 2.03254 6.0738 1.82316 6.21361C1.54116 6.40181 1.34916 6.6888 1.28276 7.0214C1.21636 7.35421 1.28356 7.693 1.47176 7.975L2.36175 9.311C2.39875 9.36659 2.46135 9.39999 2.52815 9.39999C2.59516 9.39999 2.65755 9.36659 2.69456 9.311L3.58454 7.975C3.72414 7.76561 3.79815 7.52179 3.79815 7.27C3.79815 6.56979 3.22834 6 2.52815 6ZM3.25176 7.75319L2.52815 8.83939L1.80455 7.75319C1.67555 7.55978 1.62935 7.3278 1.67494 7.0998C1.72034 6.8718 1.85194 6.67539 2.04513 6.54639C2.18873 6.45061 2.35572 6.40001 2.52813 6.40001C3.00791 6.40001 3.39812 6.79021 3.39812 7.27C3.39815 7.44238 3.34754 7.6094 3.25176 7.75319Z" />
      <path d="M2.52794 7.59982C2.74885 7.59982 2.92794 7.42073 2.92794 7.19981C2.92794 6.97889 2.74885 6.7998 2.52794 6.7998C2.30702 6.7998 2.12793 6.97889 2.12793 7.19981C2.12793 7.42073 2.30702 7.59982 2.52794 7.59982Z" />
      <path d="M8.79977 0C8.54777 0 8.30416 0.0739918 8.09499 0.213608C7.51259 0.602411 7.35478 1.39239 7.74338 1.97481L8.63337 3.31081C8.67056 3.36661 8.73298 3.40001 8.79977 3.40001C8.86657 3.40001 8.92917 3.36661 8.96637 3.31081L9.85636 1.97502C9.99616 1.76563 10.07 1.52181 10.07 1.27002C10.0698 0.569786 9.49999 0 8.79977 0ZM9.52338 1.753L8.79977 2.8392L8.07617 1.75281C7.80996 1.35381 7.91796 0.81262 8.31696 0.546208C8.46035 0.450607 8.62716 0.400005 8.79975 0.400005C9.27954 0.400005 9.66975 0.790402 9.66975 1.26981C9.66977 1.44219 9.61917 1.60919 9.52338 1.753Z" />
      <path d="M8.79991 1.60006C9.02082 1.60006 9.19991 1.42097 9.19991 1.20006C9.19991 0.979138 9.02082 0.800049 8.79991 0.800049C8.57899 0.800049 8.3999 0.979138 8.3999 1.20006C8.3999 1.42097 8.57899 1.60006 8.79991 1.60006Z" />
      <path d="M10.1999 4.9998H9.59986C9.26907 4.9998 8.99986 4.7306 8.99986 4.3998V3.7998H8.59986V4.3998C8.59986 4.95119 9.04845 5.3998 9.59986 5.3998H10.1999C10.5307 5.3998 10.7999 5.669 10.7999 5.9998C10.7999 6.33059 10.5307 6.5998 10.1999 6.5998H5.99988C5.44849 6.5998 4.99988 7.04839 4.99988 7.5998C4.99988 8.15119 5.44847 8.5998 5.99988 8.5998H7.99986C8.33066 8.5998 8.59986 8.869 8.59986 9.1998C8.59986 9.53059 8.33066 9.79979 7.99986 9.79979H2.3999V10.1998H7.99986C8.55125 10.1998 8.99986 9.75118 8.99986 9.19977C8.99986 8.64839 8.55127 8.19977 7.99986 8.19977H5.99988C5.66909 8.19977 5.39988 7.93057 5.39988 7.59977C5.39988 7.26898 5.66909 6.99978 5.99988 6.99978H10.1999C10.7512 6.99978 11.1999 6.55119 11.1999 5.99978C11.1998 5.44841 10.7512 4.9998 10.1999 4.9998Z" />
      <path d="M10.7415 3.45865L10.3415 3.05864C10.2633 2.98045 10.1369 2.98045 10.0587 3.05864L9.65869 3.45865C9.6211 3.49605 9.6001 3.54705 9.6001 3.60004V4.20004C9.6001 4.31043 9.68949 4.40003 9.80009 4.40003H10.6001C10.7107 4.40003 10.8001 4.31043 10.8001 4.20004V3.60004C10.8001 3.54705 10.7791 3.49605 10.7415 3.45865ZM10.4001 4.00005H10.0001V3.68285L10.2001 3.48286L10.4001 3.68285V4.00005H10.4001Z" />
      <path d="M7.38944 2.9369L6.98943 1.7369C6.96243 1.65511 6.88603 1.6001 6.79983 1.6001C6.71362 1.6001 6.63743 1.65511 6.61003 1.73669L6.21002 2.93669C6.18963 2.9977 6.19983 3.06468 6.23742 3.1169C6.27523 3.16931 6.33562 3.2001 6.39982 3.2001H7.19981C7.264 3.2001 7.3244 3.16931 7.36202 3.11711C7.39963 3.06492 7.41004 2.99791 7.38944 2.9369ZM6.67746 2.80012L6.79985 2.43253L6.92245 2.80012H6.67746Z" />
      <path d="M7.0001 3H6.6001V3.6H7.0001V3Z" />
      <path d="M10.7893 9.53675L10.3893 8.33676C10.3623 8.25496 10.2859 8.19995 10.1997 8.19995C10.1135 8.19995 10.0373 8.25496 10.0099 8.33676L9.60993 9.53675C9.58954 9.59755 9.59973 9.66474 9.63732 9.71696C9.67513 9.76915 9.73553 9.79995 9.79972 9.79995H10.5997C10.6639 9.79995 10.7243 9.76915 10.7619 9.71696C10.7995 9.66495 10.8099 9.59776 10.7893 9.53675ZM10.0773 9.39997L10.1997 9.03238L10.3223 9.39997H10.0773Z" />
      <path d="M10.4 9.59985H10V10.1999H10.4V9.59985Z" />
      <path d="M3.35992 3.4799C3.28453 3.3791 3.11533 3.3791 3.03993 3.4799L2.43994 4.27988L2.75993 4.51988L3.19992 3.93329L3.6399 4.51988L3.95989 4.27988L3.35992 3.4799Z" />
      <path d="M2.3598 3.07994C2.3208 3.02814 2.25799 2.99774 2.19419 3.00013C2.12939 3.00194 2.06939 3.03512 2.03339 3.08912L1.2334 4.28912L1.56619 4.51093L2.20938 3.54613L2.63978 4.11992L2.95977 3.87992L2.3598 3.07994Z" />
      <path d="M6.54131 5.05852L6.1413 4.65851C6.06309 4.5803 5.93669 4.5803 5.8585 4.65851L5.4585 5.05852C5.4209 5.09592 5.3999 5.14692 5.3999 5.19992V5.79991C5.3999 5.9103 5.4895 5.99991 5.59989 5.99991H6.39988C6.51048 5.99991 6.59988 5.9103 6.59988 5.79991V5.19992C6.59992 5.1469 6.57892 5.0959 6.54131 5.05852ZM6.19992 5.5999H5.79991V5.2827L5.9999 5.08271L6.19989 5.2827V5.5999H6.19992Z" />
      <path d="M5.78988 2.9369L5.38987 1.7369C5.36266 1.65511 5.28628 1.6001 5.20008 1.6001C5.11388 1.6001 5.03768 1.65511 5.01028 1.73669L4.61028 2.93669C4.59008 2.9977 4.60027 3.06468 4.63789 3.1169C4.67548 3.16931 4.73588 3.2001 4.8001 3.2001H5.60008C5.66428 3.2001 5.72468 3.16931 5.76229 3.11711C5.79991 3.06492 5.81029 2.99791 5.78988 2.9369ZM5.07748 2.80012L5.20008 2.43253L5.32268 2.80012H5.07748Z" />
      <path d="M5.40001 3H5V3.6H5.40001V3Z" />
    </SvgIcon>
  );
};

export default CourseIcon;
