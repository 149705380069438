import React from 'react';
import { SvgIcon } from '@material-ui/core';

const CalendarIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 145 145" {...props}>
      <path d="M136.504 82.7661V13.0273C136.504 10.681 134.602 8.7793 132.256 8.7793H110.732V4.24805C110.732 1.90166 108.831 0 106.484 0C104.138 0 102.236 1.90166 102.236 4.24805V8.7793H85.2441V4.24805C85.2441 1.90166 83.3425 0 80.9961 0C78.6497 0 76.748 1.90166 76.748 4.24805V8.7793H59.7559V4.24805C59.7559 1.90166 57.8542 0 55.5078 0C53.1614 0 51.2598 1.90166 51.2598 4.24805V8.7793H34.2676V4.24805C34.2676 1.90166 32.3659 0 30.0195 0C27.6731 0 25.7715 1.90166 25.7715 4.24805V8.7793H4.24805C1.90166 8.7793 0 10.681 0 13.0273V123.76C0 126.106 1.90166 128.008 4.24805 128.008H74.9935C81.8612 138.246 93.54 145 106.768 145C127.849 145 145 127.849 145 106.768C145 97.6852 141.814 89.3329 136.504 82.7661ZM25.7715 17.2754V21.5234C25.7715 23.8698 27.6731 25.7715 30.0195 25.7715C32.3659 25.7715 34.2676 23.8698 34.2676 21.5234V17.2754H51.2598V21.5234C51.2598 23.8698 53.1614 25.7715 55.5078 25.7715C57.8542 25.7715 59.7559 23.8698 59.7559 21.5234V17.2754H76.748V21.5234C76.748 23.8698 78.6497 25.7715 80.9961 25.7715C83.3425 25.7715 85.2441 23.8698 85.2441 21.5234V17.2754H102.236V21.5234C102.236 23.8698 104.138 25.7715 106.484 25.7715C108.831 25.7715 110.732 23.8698 110.732 21.5234V17.2754H128.008V34.2676H8.49609V17.2754H25.7715ZM8.49609 119.512V42.7637H128.008V74.9935C121.929 70.9169 114.621 68.5352 106.768 68.5352C93.3442 68.5352 81.516 75.4902 74.6926 85.9842C74.1185 85.6944 73.4713 85.5273 72.7832 85.5273H64.2871C61.9407 85.5273 60.0391 87.429 60.0391 89.7754C60.0391 92.1218 61.9407 94.0234 64.2871 94.0234H70.7222C69.7631 96.7294 69.0993 99.5747 68.773 102.52H64.2871C61.9407 102.52 60.0391 104.421 60.0391 106.768C60.0391 109.114 61.9407 111.016 64.2871 111.016H68.773C69.0993 113.96 69.762 116.806 70.7222 119.512H8.49609ZM106.768 136.504C90.3706 136.504 77.0312 123.165 77.0312 106.768C77.0312 90.3706 90.3706 77.0312 106.768 77.0312C123.165 77.0312 136.504 90.3706 136.504 106.768C136.504 123.165 123.165 136.504 106.768 136.504Z" />
      <path d="M123.76 102.52H111.016V89.7754C111.016 87.429 109.114 85.5273 106.768 85.5273C104.421 85.5273 102.52 87.429 102.52 89.7754V106.768C102.52 109.114 104.421 111.016 106.768 111.016H123.76C126.106 111.016 128.008 109.114 128.008 106.768C128.008 104.421 126.106 102.52 123.76 102.52Z" />
      <path d="M38.5156 51.543H30.0195C27.6731 51.543 25.7715 53.4446 25.7715 55.791C25.7715 58.1374 27.6731 60.0391 30.0195 60.0391H38.5156C40.862 60.0391 42.7637 58.1374 42.7637 55.791C42.7637 53.4446 40.862 51.543 38.5156 51.543Z" />
      <path d="M38.5156 68.5352H30.0195C27.6731 68.5352 25.7715 70.4368 25.7715 72.7832C25.7715 75.1296 27.6731 77.0312 30.0195 77.0312H38.5156C40.862 77.0312 42.7637 75.1296 42.7637 72.7832C42.7637 70.4368 40.862 68.5352 38.5156 68.5352Z" />
      <path d="M38.5156 85.5273H30.0195C27.6731 85.5273 25.7715 87.429 25.7715 89.7754C25.7715 92.1218 27.6731 94.0234 30.0195 94.0234H38.5156C40.862 94.0234 42.7637 92.1218 42.7637 89.7754C42.7637 87.429 40.862 85.5273 38.5156 85.5273Z" />
      <path d="M64.2871 60.0391H72.7832C75.1296 60.0391 77.0312 58.1374 77.0312 55.791C77.0312 53.4446 75.1296 51.543 72.7832 51.543H64.2871C61.9407 51.543 60.0391 53.4446 60.0391 55.791C60.0391 58.1374 61.9407 60.0391 64.2871 60.0391Z" />
      <path d="M64.2871 77.0312H72.7832C75.1296 77.0312 77.0312 75.1296 77.0312 72.7832C77.0312 70.4368 75.1296 68.5352 72.7832 68.5352H64.2871C61.9407 68.5352 60.0391 70.4368 60.0391 72.7832C60.0391 75.1296 61.9407 77.0312 64.2871 77.0312Z" />
      <path d="M38.5156 102.52H30.0195C27.6731 102.52 25.7715 104.421 25.7715 106.768C25.7715 109.114 27.6731 111.016 30.0195 111.016H38.5156C40.862 111.016 42.7637 109.114 42.7637 106.768C42.7637 104.421 40.862 102.52 38.5156 102.52Z" />
      <path d="M98.2715 60.0391H106.768C109.114 60.0391 111.016 58.1374 111.016 55.791C111.016 53.4446 109.114 51.543 106.768 51.543H98.2715C95.9251 51.543 94.0234 53.4446 94.0234 55.791C94.0234 58.1374 95.9251 60.0391 98.2715 60.0391Z" />
    </SvgIcon>
  );
};

export default CalendarIcon;
