import React from 'react';
import { SvgIcon } from '@material-ui/core';

const TypeIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 12 13" {...props}>
      <path d="M5.13928 0.568478C5.49136 -0.175496 6.50864 -0.175494 6.86072 0.568478L8.19457 3.387L11.1771 3.83897C11.9644 3.95827 12.2788 4.96723 11.7091 5.54633L9.55088 7.74024L10.0604 10.8381C10.1949 11.6558 9.37185 12.2794 8.66769 11.8933L6 10.4307L3.33231 11.8933C2.62814 12.2794 1.80515 11.6558 1.93963 10.8381L2.44912 7.74024L0.290908 5.54633C-0.278771 4.96722 0.0355858 3.95827 0.822862 3.83897L3.80543 3.38699L5.13928 0.568478ZM6 1.57692L4.8255 4.05872C4.68569 4.35415 4.41543 4.55892 4.10281 4.6063L1.47655 5.00427L3.37693 6.93609C3.60315 7.16605 3.70637 7.49737 3.65297 7.82208L3.20435 10.5498L5.55335 9.26197C5.83297 9.10866 6.16703 9.10866 6.44665 9.26197L8.79565 10.5498L8.34703 7.82208C8.29363 7.49737 8.39686 7.16605 8.62308 6.93609L10.5235 5.00427L7.8972 4.6063C7.58457 4.55892 7.31431 4.35415 7.1745 4.05872L6 1.57692Z" />
    </SvgIcon>
  );
};

export default TypeIcon;
