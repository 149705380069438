import React from 'react';
import { SvgIcon } from '@material-ui/core';

const TopicIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 32 32" {...props}>
      <path d="M24.0332 9.03748H22.5269C19.5735 9.03748 17.2036 10.7173 15.9998 12.875C14.7957 10.7168 12.4254 9.03748 9.47268 9.03748H7.96643C7.44868 9.03748 7.02893 9.45723 7.02893 9.97498V11.4812C7.02893 15.6467 10.4126 18.9458 14.4936 18.9458H15.0623V22.5727C15.0623 23.0904 15.4821 23.5102 15.9998 23.5102C16.5176 23.5102 16.9373 23.0904 16.9373 22.5727V18.9458H17.5061C21.5909 18.9458 24.9707 15.6433 24.9707 11.4812V9.97498C24.9707 9.45723 24.5509 9.03748 24.0332 9.03748ZM15.0624 17.0708H14.4936C11.4289 17.0708 8.90399 14.5918 8.90399 11.4812V10.9125H9.47274C12.5376 10.9125 15.0624 13.3916 15.0624 16.502V17.0708ZM23.0957 11.4812C23.0957 14.5952 20.5677 17.0708 17.5061 17.0708H16.9374V16.502C16.9374 13.3883 19.4652 10.9125 22.527 10.9125H23.0957V11.4812H23.0957Z" />
      <path d="M23.0292 0C20.5966 0 17.5273 1.16856 16 3.634C14.4726 1.16825 11.4014 0 8.97081 0C4.76788 0 0 3.02194 0 9.975C0 21.4549 13.085 30.4819 15.5363 31.8773C15.68 31.9591 15.84 32 16 32C16.16 32 16.32 31.9591 16.4638 31.8773C18.8458 30.5213 32 21.5164 32 9.975C32 3.19775 27.4056 0 23.0292 0V0ZM16 29.9529C13.4762 28.3086 1.875 20.1914 1.875 9.975C1.875 3.42969 6.53081 1.875 8.97081 1.875C11.4275 1.875 15.0625 3.49194 15.0625 6.9625C15.0625 7.48025 15.4823 7.9 16 7.9C16.5177 7.9 16.9375 7.48025 16.9375 6.9625C16.9375 3.49194 20.5725 1.875 23.0292 1.875C25.4698 1.875 30.125 3.43013 30.125 9.975C30.125 20.1914 18.5238 28.3086 16 29.9529Z" />
    </SvgIcon>
  );
};

export default TopicIcon;
