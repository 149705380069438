import React from 'react';
import { SvgIcon } from '@material-ui/core';

const OfferAndNeedIcon = (props) => {
  return (
    <SvgIcon width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16 0.00268555C7.16437 0.00268555 0 7.16565 0 16.002C0 24.8383 7.16367 32.0013 16 32.0013C24.837 32.0013 32 24.8383 32 16.002C32 7.16565 24.837 0.00268555 16 0.00268555ZM16 4.78666C18.9237 4.78666 21.2928 7.15651 21.2928 10.0788C21.2928 13.0017 18.9237 15.3709 16 15.3709C13.0777 15.3709 10.7086 13.0017 10.7086 10.0788C10.7086 7.15651 13.0777 4.78666 16 4.78666ZM15.9965 27.8182C13.0806 27.8182 10.4099 26.7563 8.35 24.9986C7.84819 24.5706 7.55864 23.9429 7.55864 23.2844C7.55864 20.3207 9.9573 17.9487 12.9217 17.9487H19.0797C22.0448 17.9487 24.4343 20.3207 24.4343 23.2844C24.4343 23.9436 24.1462 24.5698 23.6437 24.9979C21.5845 26.7563 18.9131 27.8182 15.9965 27.8182Z" fill="white"/>
    </SvgIcon>
  );
};

export default OfferAndNeedIcon;
