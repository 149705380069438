export default {
  dataModel: {
    types: ['pair:Place'],
    list: {
      containers: {
        cdlt: ['/regions']
      },
    },
    fieldsMapping: {
      title: 'pair:label'
    }
  },
};
