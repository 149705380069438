import React from 'react';
import { SvgIcon } from '@material-ui/core';

const TwoHandsIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 48 48" {...props}>
      <path d="M20.7808 40C20.5759 35.9997 18.3688 32.3721 14.9104 30.3512L9.384 24.2112C8.6407 23.369 7.4879 23.0148 6.4 23.2944V12.8C6.4 11.0327 4.9673 9.59998 3.2 9.59998C1.4327 9.59998 0 11.0327 0 12.8V27.9416C0.0008 29.1657 0.3127 30.3695 0.9064 31.44L8 44.2072V47.2C8 47.6418 8.3582 48 8.8 48H20C20.4418 48 20.8 47.6418 20.8 47.2V40H20.7808ZM19.2 46.4H9.6V44C9.6 43.8639 9.5653 43.7301 9.4992 43.6112L2.3048 30.6616C1.8434 29.8292 1.6009 28.8933 1.6 27.9416V12.8C1.6 11.9163 2.3163 11.2 3.2 11.2C4.0837 11.2 4.8 11.9163 4.8 12.8V24.2824C3.799 25.4457 3.822 27.1726 4.8536 28.3088L11.408 35.52L12.592 34.4432L6.0376 27.2328C5.5152 26.6582 5.5363 25.7745 6.0856 25.2256C6.653 24.6577 7.5734 24.6573 8.1413 25.2248C8.1598 25.2433 8.1777 25.2622 8.1952 25.2816L13.8056 31.5152C13.877 31.587 13.9577 31.6489 14.0456 31.6992C17.2295 33.5235 19.1953 36.9105 19.2 40.58V46.4Z" />
      <path d="M44.7999 9.59998C43.0326 9.59998 41.6 11.0327 41.6 12.8V23.2944C41.3407 23.2288 41.0738 23.1971 40.8063 23.2C39.9677 23.2187 39.1746 23.5846 38.616 24.2104L33.0911 30.3488C29.628 32.3663 27.419 35.9971 27.2191 40H27.2V47.2C27.2 47.6418 27.5582 48 28 48H39.2C39.6418 48 40 47.6418 40 47.2V44.2056L47.0056 31.508C47.6562 30.4015 47.9995 29.1413 48 27.8576V12.8C48 11.0327 46.5672 9.59998 44.7999 9.59998ZM46.4 27.8576C46.3988 28.8633 46.128 29.8504 45.6159 30.716L38.4959 43.6136C38.4318 43.7323 38.3989 43.8652 38.4 44V46.4H28.8V40.58C28.8098 36.8962 30.7923 33.4999 33.9952 31.68C34.0697 31.6377 34.137 31.5837 34.1944 31.52L39.8048 25.28C40.3436 24.6835 41.2639 24.6368 41.8604 25.1756C42.4569 25.7144 42.5036 26.6347 41.9648 27.2312L35.4079 34.4416L36.592 35.52L43.1463 28.3104C44.1789 27.1739 44.2019 25.4459 43.2 24.2824V12.8C43.2 11.9163 43.9163 11.2 44.7999 11.2C45.6837 11.2 46.4 11.9163 46.4 12.8V27.8576Z" />
      <path d="M33.6001 20H32.0001C29.1303 20.0031 26.4426 21.4067 24.8001 23.76V18.4H25.6001C30.4578 18.3943 34.3944 14.4577 34.4001 9.6C34.4001 9.1582 34.0419 8.8 33.6001 8.8H32.0001C29.1303 8.8031 26.4426 10.2067 24.8001 12.56V7.2H25.6001C29.1331 7.196 31.9961 4.333 32.0001 0.8C32.0001 0.3582 31.6419 0 31.2001 0H29.6001C27.2703 0.0014 25.1254 1.2688 24.0001 3.3088C22.8748 1.2688 20.7299 0.0014 18.4001 0H16.8001C16.3583 0 16.0001 0.3582 16.0001 0.8C16.0041 4.333 18.8671 7.196 22.4001 7.2H23.2001V12.56C21.5576 10.2067 18.8699 8.8031 16.0001 8.8H14.4001C13.9583 8.8 13.6001 9.1582 13.6001 9.6C13.6058 14.4577 17.5424 18.3943 22.4001 18.4H23.2001V23.76C21.5576 21.4067 18.8699 20.0031 16.0001 20H14.4001C13.9583 20 13.6001 20.3582 13.6001 20.8C13.6058 25.6577 17.5424 29.5943 22.4001 29.6H23.2001V36H24.8001V29.6H25.6001C30.4578 29.5943 34.3944 25.6577 34.4001 20.8C34.4001 20.3582 34.0419 20 33.6001 20ZM32.0001 10.4H32.7561C32.3441 14.0421 29.2654 16.7956 25.6001 16.8H24.8441C25.2561 13.1579 28.3348 10.4044 32.0001 10.4ZM29.6001 1.6H30.3337C29.9402 3.908 27.9414 5.5971 25.6001 5.6H24.8665C25.26 3.292 27.2588 1.6029 29.6001 1.6ZM22.4001 5.6C20.0588 5.5971 18.06 3.908 17.6665 1.6H18.4001C20.7414 1.6029 22.7402 3.292 23.1337 5.6H22.4001ZM22.4001 16.8C18.7348 16.7956 15.6561 14.0421 15.2441 10.4H16.0001C19.6654 10.4044 22.7441 13.1579 23.1561 16.8H22.4001ZM22.4001 28C18.7348 27.9956 15.6561 25.2421 15.2441 21.6H16.0001C19.6654 21.6044 22.7441 24.3579 23.1561 28H22.4001ZM25.6001 28H24.8441C25.2561 24.3579 28.3348 21.6044 32.0001 21.6H32.7561C32.3441 25.2421 29.2654 27.9956 25.6001 28Z" />
    </SvgIcon>
  );
};

export default TwoHandsIcon;
