import React from 'react';
import { SvgIcon } from '@material-ui/core';

const StepNextIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 18 33" {...props}>
      <path d="M0.292893 0.792893C-0.0976311 1.18342 -0.0976311 1.81658 0.292893 2.20711L6.58579 8.5L0.292894 14.7929C-0.0976305 15.1834 -0.0976305 15.8166 0.292894 16.2071C0.683418 16.5976 1.31658 16.5976 1.70711 16.2071L8.70711 9.20711C9.09763 8.81658 9.09763 8.18342 8.70711 7.79289L1.70711 0.792893C1.31658 0.402369 0.683417 0.402369 0.292893 0.792893Z"/>
    </SvgIcon>
  );
};

export default StepNextIcon;
